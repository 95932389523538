// --- Vendors ---
import Swiper, {
    Navigation,
    Pagination,
    Autoplay
} from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);

// --- Components ---
const singleRealisation = {

    readyFn: function () {
        let self = this;
        this.swiper();

    },

    swiper: function () {
        console.log("swiper")
        const swiper = new Swiper('.swiper', {
            slidesPerView: 1,
            autoplay: {
                delay: 5000,
                disableOnInteraction: true,
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
            },
        });

    },

}
export { singleRealisation };