// --- Vendors ---
// --- Components ---
const header = {
    contactUrl: '',

    readyFn: function () {
        let self = this;

        $('.hide-des').on('click', function () {
            $("#menu-mobile").slideToggle();
            $("body").delay('200').toggleClass("menu_actif");
        });

        var $win = $(window);
        $win.scroll(function () {
            if ($win.scrollTop() == 0) {
                $("#menu-desktop").css("background-color", "transparent");
                $("#header-mobile").css("background-color", "transparent");
            }
        });

        var position = $("header").scrollTop();

        $(window).scroll(function () {
            var scroll = $(window).scrollTop();
            if (scroll > position) {
                $("#menu-desktop").css("background-color", "#91927D");
                $("#menu-desktop").css("padding-top", "1rem");
                $("#header-mobile").css("background-color", "#91927D");
            }
        });

        //SUB-MENU
        $(".header-menu-mobile .menu-item-has-children").click(function () {
            $(this).toggleClass("menu-item-has-children-active");
            $(".sub-menu", this).slideToggle();
        });

        $(".label-halpha-menu").on("click", function () {
            $("#container-menu-mobile").toggleClass("actif");
        });

        $("#container-menu-mobile .menu-item-has-children").on("click", function () {
            $(".sub-menu", this).slideToggle();
            $(this).toggleClass("actif");
        });

        let widthDevice = window.innerWidth;
        if (widthDevice < 800) {
            $("footer").addClass("mobile-footer");
        }

        /* FOOTER SUB-MENU */
        $("footer.mobile-footer .menu-item-has-children").on("click", function () {
            $(this).toggleClass("actif");
            $(".sub-menu", this).slideToggle();
        });
    },
}
export { header };